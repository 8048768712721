<template>
  <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content"
    :esc-to-close="true"
    :click-to-close="true"
    :styles="{
      width:'80%',
      margin: '0 auto'
    }"
    :content-style="{
      width: 'auto',
      background: '#fff', 
      marginTop: '25px' 
    }"
  >
    <p class="is-flex bg-gradient-to-r from-slate-50 to-slate-300"> 
        
      <span class="subtitle m-2">Dokument per (job info...)</span>
      <button  @click="close" class="delete ml-auto m-3"></button>

    </p>
    <div class="modal__content p-2">
      <div class="has-background-white mt-1 cities-modal">
        <!-- Conditional:` No printable services -->
        <!-- <div v-if="$vfm.dynamicModals[0].params.is_empty">
          <p class="has-text-centered">
            Kjo flete pune nuk ka asnje sherbim te printueshem.
          </p>
        </div> -->
        <div v-if="print_documents.data && print_documents.data.length > 0" :class="[print_documents.data.length == 1 ? 'grid-cols-1' : `grid-cols-${print_documents.data.length}`]" class="grid gap-2 auto-cols-fr">
          <div v-for="(doc, index) in print_documents.data" :key="index" class="box py-3 bg-white h-full" >
            <div class="is-flex is-flex-direction-column h-full">
                {{doc.name}}
                <div :id="'id_' + doc.guid" 
                  v-html="useJobStore().get_print_html(doc.content, $vfm.dynamicModals[0].params.job, service_types[doc.service_type])"
                    class="border mt-1 is-flex-grow-1 p-1" ></div>
                <div class="my-2">
                  <a @click.prevent="print_document(doc)" 
                  href="" class="button is-success is-small is-block w-100">Printo</a>
                </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </vue-final-modal>
</template>
<script setup>
    import { useJobStore } from '@/stores/job'
    // import { useProcessStore } from '@/stores/process'
    import { usePrintStore } from '@/stores/print'
</script>
<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
    components: {
      VueFinalModal,
    },
    data() {
        return {
            service_types: {},
            print_documents: { empty: true }
        }
    },
    async created(){

        
      await this.$vfm.dynamicModals[0].params.job?.document_services.map(s => {
          if(!this.service_types[s.service_type_id]){
              this.service_types[s.service_type_id] = []
              this.service_types[s.service_type_id].push(s)
          }
          else {
              this.service_types[s.service_type_id].push(s)
          }
      })
      
      this.print_documents = await useJobStore().get_job_services_print(Object.keys(this.service_types))
    },
    methods: {
        print_document(doc){
            var content = useJobStore().get_print_html(doc.content, this.val_params, this.service_types[doc.service_type])
            usePrintStore().print_entity(content)

        },
    },

  
  computed: {
    val_params(){
     return this.$vfm.dynamicModals[0]?.params?.job 
    }
//     service_types(){
//         var list = {}
//         this.$vfm.dynamicModals[0].params.job.document_services.data.map(s => {
//             if(!list[s.service_type_id]){
//                 list[s.service_type_id] = []
//             }
//             else {
//                 list[s.service_type_id].push(s)
//             }
//         })
//         return list
//     }
   },
}
</script>

<style>
.cities-modal {
  min-width: 800px;
  min-height: 500px;
}
</style>

